'use client';

import { Content, Description, Overlay, Portal, Root, Title } from '@radix-ui/react-dialog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useReCaptcha as useReCaptchaLib } from 'next-recaptcha-v3';
import Script from 'next/script';

import { envConfig } from '#app/config';
import { useCommunitiesFlags } from '#context/FeatureFlags';

declare global {
  interface Window {
    turnstile: {
      render: (container: HTMLElement, options: any) => string;
      reset: (widgetId: string) => void;
      remove: (widgetId: string) => void;
    };
  }
}

export type CaptchaToken =
  | {
      turnstileToken: string;
    }
  | {
      reCaptchaToken: string;
    }
  | undefined;

const isCypressTest = typeof window !== 'undefined' && window.CYPRESS_TEST_ENV === true;

/**
 * A simplified captcha hook that uses Turnstile in interaction-only mode,
 * or falls back to reCAPTCHA if Turnstile is not enabled.
 *
 * Usage:
 * const { CaptchaWidget, getToken } = useCaptcha('myAction');
 *
 * Call getToken() to trigger rendering of the widget and retrieve a token.
 * It returns { reCaptchaToken: string } when using reCAPTCHA or
 * { turnstileToken: string } when using Turnstile.
 * Remove console.log once Turnstile is fully deployed and working.
 */
export const useCaptcha = (action: string) => {
  const t = useTranslations('captcha');

  const { turnstile } = useCommunitiesFlags(['turnstile']);
  const useTurnstile =
    turnstile.enabled && (turnstile.value as undefined | string)?.includes(action);

  // reCAPTCHA fallback
  const { executeRecaptcha, loaded: recaptchaLoaded } = useReCaptchaLib();

  const turnstileScriptLoadedRef = useRef(false);
  const [widgetId, setWidgetId] = useState<string | null>(null);
  const [shouldRenderWidget, setShouldRenderWidget] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const tokenPromiseRef = useRef<{
    resolve: (token: string) => void;
    reject: (error: Error) => void;
  } | null>(null);

  useEffect(() => {
    if (window.turnstile && !turnstileScriptLoadedRef.current) {
      console.log('🌐 Detected global turnstile object, script already loaded');
      turnstileScriptLoadedRef.current = true;
    }
  }, []);

  const cleanupWidget = useCallback(() => {
    if (window.turnstile && widgetId) {
      try {
        window.turnstile.remove(widgetId);
        setWidgetId(null);
      } catch (e) {
        console.error('Failed to remove turnstile widget:', e);
        setWidgetId(null);
      }
    }
  }, [widgetId]);

  const handleTurnstileSuccess = useCallback(
    (token: string) => {
      console.log('🔍 handleTurnstileSuccess called with token:', token?.slice(0, 10) + '...');

      if (tokenPromiseRef.current) {
        tokenPromiseRef.current.resolve(token);
        tokenPromiseRef.current = null;
      }

      setDialogOpen(false);

      // Use a timeout to ensure the dialog animation completes before cleanup
      setTimeout(() => {
        setShouldRenderWidget(false);
        cleanupWidget();
      }, 300);
    },
    [cleanupWidget],
  );

  // Define renderWidget first (moved up)
  const renderWidget = useCallback(() => {
    console.log('🎯 renderWidget called', {
      hasTurnstile: !!window.turnstile,
      hasContainer: !!containerRef.current,
      currentWidgetId: widgetId,
    });

    cleanupWidget();

    if (window.turnstile && containerRef.current) {
      const id = window.turnstile.render(containerRef.current, {
        sitekey: envConfig.turnstile.siteKey,
        action,
        callback: handleTurnstileSuccess,
        size: 'normal',
        appearance: 'interaction-only',
        'before-interactive-callback': () => {
          console.log('🎬 before-interactive-callback triggered');
          setDialogOpen(true);
        },
      });
      console.log('🆔 New widget rendered with ID:', id);
      setWidgetId(id);
    }
  }, [action, handleTurnstileSuccess, cleanupWidget, widgetId]);

  const forceRender = useCallback(() => {
    console.log('🔄 Force render triggered');
    // Use a small timeout to ensure DOM has time to update
    setTimeout(() => {
      if (containerRef.current && window.turnstile && !widgetId && shouldRenderWidget) {
        console.log('👉 Container is available, rendering widget');
        renderWidget();
      } else {
        console.log('⚠️ Container not ready:', {
          hasContainer: !!containerRef.current,
          hasTurnstile: !!window.turnstile,
          widgetId,
          shouldRender: shouldRenderWidget,
        });
      }
    }, 50);
  }, [renderWidget, widgetId, shouldRenderWidget]);

  // Effect to render the widget when the script is loaded and the widget is requested.
  useEffect(() => {
    console.log('🔄 Render effect triggered:', {
      useTurnstile,
      turnstileScriptLoaded: turnstileScriptLoadedRef.current,
      shouldRenderWidget,
      hasContainer: !!containerRef.current,
      widgetId,
    });

    if (
      useTurnstile &&
      turnstileScriptLoadedRef.current &&
      shouldRenderWidget &&
      containerRef.current &&
      !widgetId
    ) {
      console.log('✨ Conditions met, calling renderWidget');
      renderWidget();
    }
  }, [useTurnstile, shouldRenderWidget, widgetId, renderWidget]);

  // Effect to check container availability when script loads
  useEffect(() => {
    if (turnstileScriptLoadedRef.current && shouldRenderWidget) {
      forceRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnstileScriptLoadedRef.current, shouldRenderWidget, forceRender]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupWidget();
    };
  }, [cleanupWidget]);

  /**
   * getToken: Returns a promise that resolves with the captcha token.
   * - For reCAPTCHA, it executes the recaptcha function and returns { reCaptchaToken: string }.
   * - For Turnstile, it triggers widget rendering (if not already rendered), resets the widget
   *   for a fresh verification, and returns a promise that resolves to { turnstileToken: string }.
   * If verification fails, it may return undefined.
   */
  const getToken = useCallback((): Promise<CaptchaToken> => {
    console.log('🎫 getToken called:', {
      useTurnstile,
      widgetId,
      shouldRenderWidget,
      hasTurnstileGlobal: !!window.turnstile,
    });

    if (isCypressTest) {
      console.log('🧪 Cypress test environment detected, returning dummy token');
      return Promise.resolve(
        useTurnstile
          ? { turnstileToken: 'cypress_dummy_turnstile_token' }
          : { reCaptchaToken: 'cypress_dummy_recaptcha_token' },
      );
    }

    if (window.turnstile && !turnstileScriptLoadedRef.current) {
      console.log('🌐 Detected global turnstile object during getToken');
      turnstileScriptLoadedRef.current = true;
    }

    if (!useTurnstile) {
      if (!recaptchaLoaded) {
        return Promise.reject(new Error('reCAPTCHA not loaded'));
      }
      return executeRecaptcha(action).then(token =>
        token ? { reCaptchaToken: token } : undefined,
      );
    }

    if (widgetId) {
      cleanupWidget();
    }

    // Use a small timeout to avoid state update conflicts
    setTimeout(() => {
      setShouldRenderWidget(true);
    }, 0);

    return new Promise((resolve, reject) => {
      console.log('⏳ Setting up new promise');
      tokenPromiseRef.current = {
        resolve: (token: string) => resolve(token ? { turnstileToken: token } : undefined),
        reject,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useTurnstile, executeRecaptcha, action, recaptchaLoaded, cleanupWidget, widgetId]);

  // The CaptchaWidget component now renders a Radix UI Dialog with the Turnstile widget inside
  const CaptchaWidget =
    useTurnstile && shouldRenderWidget ? (
      <>
        {!turnstileScriptLoadedRef.current && !window.turnstile && (
          <Script
            src="https://challenges.cloudflare.com/turnstile/v0/api.js"
            strategy="afterInteractive"
            onLoad={() => {
              console.log('📜 Turnstile script loaded');
              turnstileScriptLoadedRef.current = true;
              setShouldRenderWidget(true);
              // Try to render after a short delay to ensure DOM is ready
              setTimeout(forceRender, 100);
            }}
          />
        )}
        <Root open={shouldRenderWidget}>
          <Portal>
            <Overlay
              className={`bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-30 transition-opacity duration-200 ${
                dialogOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
            />
            <Content
              {...(dialogOpen
                ? {
                    onPointerDownOutside: e => e.preventDefault(),
                    onInteractOutside: e => e.preventDefault(),
                    onEscapeKeyDown: e => e.preventDefault(),
                  }
                : {})}
              className={`fixed left-[50%] top-[50%] z-50 max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-popover border border-modal bg-modal p-200 shadow-popover focus:outline-none transition-opacity duration-200 ${
                dialogOpen ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <Title className="body-component-lg-bold mb-100 text-center">{t('title')}</Title>
              <Description className="body-component-md text-text-secondary mb-200 text-center">
                {t('description')}
              </Description>
              <div className="flex justify-center items-center">
                <div ref={containerRef} />
              </div>
            </Content>
          </Portal>
        </Root>
      </>
    ) : null;

  return { CaptchaWidget, getToken };
};
